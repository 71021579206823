:root {
  --main-text-color: #1a191c;
  --title-color: #282b47;
  --logo-color: #4343e5;
  --footer-text-color: #f3e9e9;

  --accent-background-color: #2f303a;
  --secondary-background-color: #f4f4fd;
}

@font-face {
  font-family: 'HeliosCond';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/HeliosCond/HeliosCond.ttf') format('truetype');
}

@font-face {
  font-family: 'HeliosCond';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/HeliosCond/HeliosCond-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'OpenSans', sans-serif;
  font-size: 12px;
  height: 100%;
  min-height: 100%;
  color: var(--main-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
